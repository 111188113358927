<template>
  <div>
    <ContentPage v-if="isContentPage" />
    <DefaultPlp v-else-if="isPlp" />
  </div>
</template>

<script setup lang="ts">
import usePlpTypeState from '~/composables/usePlpTypeState'
import { getIsInEditorMode } from '~/utils/cms'
import { PAGE_TYPE_CONTENT_PAGES } from '~/constants/pageType'

const { setPageState } = usePageState()
const route = useRoute()
const { data: plpType } = await usePlpTypeState(
  route.path,
  getIsInEditorMode(route.query),
)

watch(
  plpType,
  (newValue) => {
    if (newValue) {
      const pageType =
        newValue === PAGE_TYPE_CONTENT_PAGES ? 'content' : 'category'

      route.meta.pageType = pageType

      setPageState('pageType', pageType)
      setPageState('plpType', newValue)
    }
  },
  { immediate: true },
)

const isContentPage = computed(() => plpType.value === PAGE_TYPE_CONTENT_PAGES)
const isPlp = computed(
  () =>
    plpType.value &&
    [
      PAGE_TYPE_DEFAULT_PLP,
      PAGE_TYPE_LENSES_PLP,
      PAGE_TYPE_LENSES_CARE_PLP,
    ].includes(plpType.value),
)

if (!plpType.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Not Found',
  })
}
</script>
