import { normalizePath } from '~/rpcMethods'

export default async (_path: string, isInEditorMode: boolean) => {
  const $currentShop = useCurrentShop()
  const path = normalizePath(_path)
  const key = `${$currentShop.value.locale}${path}:${isInEditorMode ? 'preview' : 'published'}`

  const data = useState<PlpPageType | undefined>(`${key}-data`)
  const fetching = useState(`${key}-fetching`, () => false)

  const { data: cmsContentPageData, fetch: fetchCmsData } = useFimRpc(
    'getCmsStory',
    'use-page-type-cms',
  )
  const { data: categoryPaths, fetch: fetchCategoryPaths } = useFimRpc(
    'getCategoryPaths',
    'use-page-type-categories',
  )
  const { data: pageTypeData, fetch: fetchPageTypeData } = useFimRpc(
    'getPageTypes',
    'use-page-type-pageTypes',
  )

  const checkContentPages = async (
    normalizedPath: string,
    isInEditorMode: boolean,
  ) => {
    try {
      await fetchCmsData({
        slug: normalizedPath,
        baseFolder: getLocalizedBaseFolder(
          $currentShop.value,
          CmsBaseFolder.Content,
        ),
        isCmsPreview: isInEditorMode,
        ignoreNotFound: true,
      })
      if (
        cmsContentPageData?.value?.full_slug &&
        (cmsContentPageData?.value?.full_slug?.includes(path) ||
          (isInEditorMode &&
            cmsContentPageData?.value?.translated_slugs
              ?.map((slug: { path: string }) => normalizeFullSlug(slug.path))
              ?.includes(path)))
      ) {
        return PAGE_TYPE_CONTENT_PAGES
      }
    } catch (error) {
      // todo add logger after memory-leak-6 is merged
    }
  }

  const checkCategoryPaths = async (normalizedPath: string, locale: string) => {
    await fetchCategoryPaths()
    const found = categoryPaths.value?.includes(normalizedPath)
    const contactLensPath = getContactLensesPath(locale)
    const lensesCarePath = getLensesCarePath(locale)
    if (found && normalizedPath.startsWith(lensesCarePath)) {
      return PAGE_TYPE_LENSES_CARE_PLP
    }

    if (found && normalizedPath.startsWith(contactLensPath)) {
      return PAGE_TYPE_LENSES_PLP
    }

    if (found) {
      return PAGE_TYPE_DEFAULT_PLP
    }
  }

  const checkPageTypes = async (
    normalizedPath: string,
    isInEditorMode: boolean,
  ) => {
    await fetchPageTypeData({ isCmsPreview: isInEditorMode })
    return pageTypeData.value?.mapping?.[normalizedPath]
  }

  const fetch = async () => {
    // don't fetch if we already have a value.
    // pagetypes normally won't change
    if (data.value) {
      return
    }

    fetching.value = true
    const locale = $currentShop.value.locale

    try {
      // first check cached pageTypes
      const pageType = await checkPageTypes(path, false)
      if (pageType) {
        data.value = pageType
        fetching.value = false
        return
      }

      // categories have higher priority
      const plpType = await checkCategoryPaths(path, locale)
      if (plpType) {
        data.value = plpType
        fetching.value = false
        return
      }

      // check content pages & respect editor mode
      const contentPageType = await checkContentPages(path, isInEditorMode)
      if (contentPageType) {
        data.value = contentPageType
        fetching.value = false
        return
      }

      console.log('\x1B[31m No pageType for:', path)
      fetching.value = false
      data.value = undefined
    } catch (error) {
      console.error(error)
      fetching.value = false
      data.value = undefined
    }
  }

  await fetch()

  return { data, fetch, fetching, cmsContentPageData }
}
