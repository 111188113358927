import { getIsoLanguage } from './locale'

// Hopefully a temporary workaround until we have a robust way to determine
// category based on slug or some other attribute
const contactLensesPaths = {
  de: '/kontaktlinsen',
  pl: '/soczewki-kontaktowe',
  cs: '/kontaktni-cocky',
  fr: '/lentilles-de-contact',
  it: '/lenti-a-contatto',
}

const lensesCarePaths = {
  de: '/kontaktlinsen/kontaktlinsenpflege',
  pl: '/soczewki-kontaktowe/srodki-do-pielegnacji-soczewek',
  cs: '/kontaktni-cocky/prostredky-na-peci-o-kontaktni-cocky',
  fr: '/lentilles-de-contact/entretien-des-lentilles-de-contact',
  it: '/lenti-a-contatto/cura-delle-lenti-a-contatto',
}

export const getContactLensesPath = (locale: string) => {
  const lang = getIsoLanguage(locale)
  return contactLensesPaths[lang as keyof typeof contactLensesPaths]
}

export const getLensesCarePath = (locale: string) => {
  const lang = getIsoLanguage(locale)
  return lensesCarePaths[lang as keyof typeof lensesCarePaths]
}
