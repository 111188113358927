import type { Category, RpcContext } from '@scayle/storefront-nuxt'
import type { ISbStoriesParams } from 'storyblok-js-client'
import {
  PAGE_TYPE_CONTENT_PAGES,
  PAGE_TYPE_DEFAULT_PLP,
  PAGE_TYPE_LENSES_CARE_PLP,
  PAGE_TYPE_LENSES_PLP,
  type PlpPageTypeMapping,
} from '../constants/pageType'
import { getTtl } from '../utils/cache'
import { getContactLensesPath, getLensesCarePath } from '../utils/categories'
import { withLogging } from '../utils/log'
import { getStoryblokClient } from './cms'

export const fetchStoryblokPaths = async (
  accessToken: string,
  localePrefix: string = '',
  options: { isCmsPreview: boolean } = { isCmsPreview: false },
  allowedPageTypes = ['CmsContentPage', 'ContentPage', 'Page'],
) => {
  const storyblok = getStoryblokClient(accessToken)
  const runtimeConfig = useRuntimeConfig()

  const environment = runtimeConfig.public.appEnv
  const isAllowedDraft =
    environment &&
    ['test', 'staging', 'integration', 'development'].includes(environment)

  const params: ISbStoriesParams = {
    version: isAllowedDraft && options?.isCmsPreview ? 'draft' : 'published',
    per_page: 100,
  }
  if (localePrefix) {
    params.starts_with = `[default]/${localePrefix.toLowerCase()}/content`
  }

  const response = await storyblok.getAll('cdn/stories', params)

  const result = response.map((item) => {
    return {
      pageType: item.content.component,
      path: item.full_slug,
    }
  })

  return [
    ...new Set(
      result
        .filter((item) => allowedPageTypes.includes(item.pageType))
        .map((item) => item.path.replace(/^.*\/content\//, '/'))
        .filter((path) => path.startsWith('/')),
    ),
  ]
}

export const fetchCategoryPaths = async (
  client: RpcContext['bapiClient'],
): Promise<string[]> => {
  const flatten = (categories: Category[]): Category[] =>
    categories.flatMap((category) => [
      category,
      ...flatten(category.children ?? []),
    ])

  const categories = await client.categories.getRoots({
    with: { children: 10 },
    includeHidden: true,
  })

  return [
    ...new Set(
      flatten(categories || []).flatMap((category) => [
        category.path,
        `/${category.id}`,
      ]),
    ),
  ]
}

export const normalizePath = (path: string) => {
  return `/${path.split('/').filter(Boolean).join('/')}/`.replace(/\/+/, '/')
}

export const getMapping = async (
  context: RpcContext,
  localePrefix: string,
  options: { isCmsPreview: boolean } = { isCmsPreview: false },
) => {
  let storyblokPaths: any[] = []
  const accessToken = context?.runtimeConfiguration?.storyblok?.accessToken

  // let time = Date.now()
  try {
    /**
     * Takes +2 seconds. Therefore we cache it separately with extended TTL (until cache clear/warmup).
     * When there is an update in storyblok, we update the storyblokPaths within the cache using a webhook.
     * See: updateStoryblokPaths in server-middleware/cache.ts
     */
    if (options.isCmsPreview) {
      storyblokPaths = await fetchStoryblokPaths(
        accessToken,
        localePrefix,
        options,
      )
    } else {
      storyblokPaths = await context.cached(
        withLogging(fetchStoryblokPaths, 'fetchStoryblokPaths'),
        {
          cacheKeyPrefix: 'storyblokPaths',
          ttl: getTtl(),
        },
      )(accessToken, localePrefix, options)
    }
  } catch (error) {
    console.error('ERROR fetchStoryblokPaths', error)
  }

  let categoryPaths: any[] = []
  try {
    categoryPaths = await fetchCategoryPaths(context.bapiClient)
  } catch (error) {
    console.error('ERROR fetchCategoryPaths', error)
  }

  const paths = [...new Set([...storyblokPaths, ...categoryPaths])]

  if (!paths.length) {
    console.error('ERROR getMapping paths empty', paths)
    return
  }

  const contactLensPath = getContactLensesPath(localePrefix)
  const lensesCarePath = getLensesCarePath(localePrefix)

  const entries = paths.map((path) => {
    const normalizedPath = normalizePath(path)
    if (storyblokPaths.includes(path)) {
      return [normalizedPath, PAGE_TYPE_CONTENT_PAGES]
    }
    if (path.startsWith(lensesCarePath)) {
      return [normalizedPath, PAGE_TYPE_LENSES_CARE_PLP]
    } else if (path.startsWith(contactLensPath)) {
      return [normalizedPath, PAGE_TYPE_LENSES_PLP]
    }

    return [normalizedPath, PAGE_TYPE_DEFAULT_PLP]
  })

  return Object.fromEntries(entries) as PlpPageTypeMapping
}

export const getPageTypes = async (
  { isCmsPreview }: { isCmsPreview: boolean },
  context: RpcContext,
) => {
  const pathPrefix = context.locale
  const mapping = isCmsPreview
    ? await getMapping(context, pathPrefix, { isCmsPreview: true })
    : await context.cached(withLogging(getMapping, 'getMapping'), {
        cacheKeyPrefix: 'getPageTypesMapping',
      })(context, pathPrefix, {
        isCmsPreview: false,
      })

  return { mapping, pathPrefix }
}
